import request from '@/utils/request'

export function login(params) {
  return request({
    url: 'webApi/login',
    method: 'post',
    data: params
  })
}

export function getVerificationCode(query) {
  return request({
    url: 'webApi/getVerificationCode',
    method: 'get',
    params: query
  })
}

export function selectIdentity(params) {
  return request({
    url: 'web/api/user/selectIdentity',
    method: 'post',
    data: params
  })
}

export function getUserInfo(query) {
  return request({
    url: 'web/api/user/getUserInfo',
    method: 'get',
    params: query
  })
}

export function getAreaList(query) {
  return request({
    url: 'webApi/common/getAreaList',
    method: 'get',
    params: query
  })
}

export function getDictData(query) {
  return request({
    url: 'webApi/common/getDictData',
    method: 'get',
    params: query
  })
}
export function setBasicInfo(params) {
  return request({
    url: 'web/api/jobSeekers/setBasicInfo',
    method: 'post',
    data: params
  })
}

export function getResumeInfo(query) {
  return request({
    url: 'web/api/jobSeekers/getResumeInfo',
    method: 'get',
    params: query
  })
}

export function getPosition(query) {
  return request({
    url: 'webApi/common/getPosition',
    method: 'get',
    params: query
  })
}

export function getCompanyIndustry(query) {
  return request({
    url: 'webApi/common/getCompanyIndustry',
    method: 'get',
    params: query
  })
}

export function getSalaryRequirements(query) {
  return request({
    url: 'webApi/common/getSalaryRequirements',
    method: 'get',
    params: query
  })
}
export function jobSeekingIntention(params) {
  return request({
    url: 'web/api/jobSeekers/jobSeekingIntention',
    method: 'post',
    data: params
  })
}

export function resumeEducation(params) {
  return request({
    url: 'web/api/jobSeekers/resumeEducation',
    method: 'post',
    data: params
  })
}

export function workHistory(params) {
  return request({
    url: 'web/api/jobSeekers/workHistory',
    method: 'post',
    data: params
  })
}

export function projectExperience(params) {
  return request({
    url: 'web/api/jobSeekers/projectExperience',
    method: 'post',
    data: params
  })
}

export function resumeOtherInfo(params) {
  return request({
    url: 'web/api/jobSeekers/resumeOtherInfo',
    method: 'post',
    data: params
  })
}
export function resumeProfessionalSkills(params) {
  return request({
    url: 'web/api/jobSeekers/resumeProfessionalSkills',
    method: 'post',
    data: params
  })
}
export function removeEducation(params) {
  return request({
    url: 'web/api/jobSeekers/removeEducation',
    method: 'post',
    data: params
  })
}
export function removeProjectExperience(params) {
  return request({
    url: 'web/api/jobSeekers/removeProjectExperience',
    method: 'post',
    data: params
  })
}

export function removeWorkHistory(params) {
  return request({
    url: 'web/api/jobSeekers/removeWorkHistory',
    method: 'post',
    data: params
  })
}
export function removeProfessionalSkills(params) {
  return request({
    url: 'web/api/jobSeekers/removeProfessionalSkills',
    method: 'post',
    data: params
  })
}
export function getAdvert(query) {
  return request({
    url: 'webApi/common/getAdvert',
    method: 'get',
    params: query
  })
}
export function getJobList(query) {
  return request({
    url: 'web/api/position/getJobSeekersViewJobListings',
    method: 'get',
    params: query
  })
}
export function getCompanyDetail(query) {
  return request({
    url: 'web/api/position/getJobApplicantBusinessDetails',
    method: 'get',
    params: query
  })
}
export function verifyRealName(query) {
  return request({
    url: 'web/api/recruiters/verifyRealName',
    method: 'get',
    params: query
  })
}
export function addContacts(params) {
  return request({
    url: 'web/api/recruiters/addContacts',
    method: 'post',
    data: params
  })
}
export function getContactsInfo(query) {
  return request({
    url: 'web/api/recruiters/getContactsInfo',
    method: 'get',
    params: query
  })
}
export function addEnterpriseInfo(params) {
  return request({
    url: 'web/api/recruiters/addEnterpriseInfo',
    method: 'post',
    data: params
  })
}
export function setWeChat(params) {
  return request({
    url: 'web/api/user/setWeChat',
    method: 'post',
    data: params
  })
}

export function addQualification(params) {
  return request({
    url: 'web/api/recruiters/addQualification',
    method: 'post',
    data: params
  })
}
export function getRecruiterEnterpriseDetails(query) {
  return request({
    url: 'web/api/recruiters/getRecruiterEnterpriseDetails',
    method: 'get',
    params: query
  })
}

export function verificationEnterprise(query) {
  return request({
    url: 'web/api/recruiters/verificationEnterprise',
    method: 'get',
    params: query
  })
}
export function getEnterpriseInfo(query) {
  return request({
    url: 'web/api/recruiters/getEnterpriseInfo',
    method: 'get',
    params: query
  })
}
export function addIndividualQualification(params) {
  return request({
    url: 'web/api/recruiters/addIndividualQualification',
    method: 'post',
    data: params
  })
}
export function viewPersonalApprovalStatus(query) {
  return request({
    url: 'web/api/recruiters/viewPersonalApprovalStatus',
    method: 'get',
    params: query
  })
}
export function certification(params) {
  return request({
    url: 'web/api/user/certification',
    method: 'post',
    data: params
  })
}
export function verifyRealNameAuth(params) {
  return request({
    url: 'web/api/user/verifyRealNameAuthentication',
    method: 'post',
    data: params
  })
}
export function getWorkAddressList(query) {
  return request({
    url: 'web/api/position/getWorkAddressList',
    method: 'get',
    params: query
  })
}
export function addWorkAddress(params) {
  return request({
    url: 'web/api/position/addWorkAddress',
    method: 'post',
    data: params
  })
}
export function postPositionInfo(params) {
  return request({
    url: 'web/api/position/postPositionInfo',
    method: 'post',
    data: params
  })
}
export function getEnterprisePositionList(query) {
  return request({
    url: 'web/api/position/getEnterprisePositionList',
    method: 'get',
    params: query
  })
}
export function getJobDetails(query) {
  return request({
    url: 'web/api/position/getJobDetails',
    method: 'get',
    params: query
  })
}
export function setPositionStatus(params) {
  return request({
    url: 'web/api/position/setPositionStatus',
    method: 'post',
    data: params
  })
}

export function getJobApplicantPositionList(query) {
  return request({
    url: 'web/api/position/getJobApplicantPositionList',
    method: 'get',
    params: query
  })
}
export function logOut(params) {
  return request({
    url: 'webApi/logOut',
    method: 'post',
    data: params
  })
}
export function getResumeList(query) {
  return request({
    url: 'web/api/jobSeekers/getResumeList',
    method: 'get',
    params: query
  })
}
export function getExaminationList(query) {
  return request({
    url: 'webApi/company/getExaminationList',
    method: 'get',
    params: query
  })
}
export function getExaminationListTwo(query) {
  return request({
    url: 'webApi/company/getExaminationListTwo',
    method: 'get',
    params: query
  })
}
export function getExaminationListThree(query) {
  return request({
    url: 'webApi/company/getExaminationListThree',
    method: 'get',
    params: query
  })
}
export function getExaminationListFour(query) {
  return request({
    url: 'webApi/company/getExaminationListFour',
    method: 'get',
    params: query
  })
}
export function getExaminationInfo(query) {
  return request({
    url: 'webApi/company/getExaminationInfo',
    method: 'get',
    params: query
  })
}
export function getCompanyDynamicsList(query) {
  return request({
    url: 'webApi/company/getCompanyDynamicsList',
    method: 'get',
    params: query
  })
}
export function getCompanyDynamics(query) {
  return request({
    url: 'webApi/company/getCompanyDynamics',
    method: 'get',
    params: query
  })
}
export function getCompanyInfo(query) {
  return request({
    url: 'webApi/company/getCompanyInfo',
    method: 'get',
    params: query
  })
}
export function getMessageList(query) {
  return request({
    url: 'web/api/ImFriend/list',
    method: 'get',
    params: query
  })
}
export function getChatHistory(query) {
  return request({
    url: 'web/api/ImPrivate/history',
    method: 'get',
    params: query
  })
}
export function sendMsg(params) {
  return request({
    url: 'web/api/ImPrivate/send',
    method: 'post',
    data: params
  })
}
export function setReadMessage(query) {
  return request({
    url: 'web/api/ImPrivate/setReadMessage',
    method: 'get',
    params: query
  })
}
export function addFriend(params) {
  return request({
    url: 'web/api/ImFriend/add',
    method: 'post',
    data: params
  })
}
export function searchTermsPosition(query) {
  return request({
    url: 'webApi/common/searchTermsPosition',
    method: 'get',
    params: query
  })
}
export function getJobSegmentation(query) {
  return request({
    url: 'webApi/common/getJobSegmentation',
    method: 'get',
    params: query
  })
}
export function getVerifyResumeFilling(query) {
  return request({
    url: 'web/api/jobSeekers/getVerifyResumeFilling',
    method: 'get',
    params: query
  })
}
export function removalMessage(query) {
  return request({
    url: 'web/api/ImFriend/removalMessage/'+query,
    method: 'DELETE'
  })
}
export function collectPosition(params) {
  return request({
    url: 'web/api/collect/collectionPosition',
    method: 'post',
    data: params
  })
}
export function cancelFavorite(params) {
  return request({
    url: 'web/api/collect/cancelFavorite/'+params,
    method: 'delete'
  })
}
export function favoriteUsers(params) {
  return request({
    url: 'web/api/collect/favoriteUsers',
    method: 'post',
    data: params
  })
}
export function collectionResume(params) {
  return request({
    url: 'web/api/collect/collectionResume',
    method: 'post',
    data: params
  })
}
export function getFavoriteUsersList(query) {
  return request({
    url: 'web/api/collect/getFavoriteUsersList',
    method: 'get',
    params: query
  })
}
export function getCollectionPosition(query) {
  return request({
    url: 'web/api/collect/getCollectionPosition',
    method: 'get',
    params: query
  })
}
export function collectionCompany(params) {
  return request({
    url: 'web/api/collect/collectionCompany',
    method: 'post',
    data: params
  })
}
export function getCollectionCompany(query) {
  return request({
    url: 'web/api/collect/getCollectionCompany',
    method: 'get',
    params: query
  })
}
export function addBrowsePosition(params) {
  return request({
    url: 'web/api/footsteps/addBrowsePosition',
    method: 'post',
    data: params
  })
}
export function addEnterpriseBrowsingUsers(params) {
  return request({
    url: 'web/api/footsteps/addEnterpriseBrowsingUsers',
    method: 'post',
    data: params
  })
}
export function getUserBrowsingInfo(query) {
  return request({
    url: 'web/api/footsteps/getUserBrowsingInfo',
    method: 'get',
    params: query
  })
}
export function getPositionBrowsingInfo(query) {
  return request({
    url: 'web/api/footsteps/getPositionBrowsingInfo',
    method: 'get',
    params: query
  })
}
export function unbindTheEnterprise(query) {
  return request({
    url: 'web/api/recruiters/unbindTheEnterprise',
    method: 'get',
    params: query
  })
}
export function getAttachmentResume(query) {
  return request({
    url: 'web/api/jobSeekers/getAttachmentResume',
    method: 'get',
    params: query
  })
}
export function attachmentResume(params) {
  return request({
    url: 'web/api/jobSeekers/attachmentResume',
    method: 'post',
    data: params
  })
}
export function delAttachmentResume() {
  return request({
    url: 'web/api/jobSeekers/delAttachmentResume',
    method: 'DELETE'
  })
}
export function getRecruitmentPositionNum(query) {
  return request({
    url: 'web/api/position/getRecruitmentPositionNum',
    method: 'get',
    params: query
  })
}
export function getPopularEnterprises(query) {
  return request({
    url: 'web/api/enterpriseInfo/getPopularEnterprises',
    method: 'get',
    params: query
  })
}
export function getOnlineJobFairs(query) {
  return request({
    url: 'web/api/enterpriseInfo/getOnlineJobFairs',
    method: 'get',
    params: query
  })
}
export function verifyMobileWeChatResume(query) {
  return request({
    url: 'web/api/ImPrivate/verifyMobileWeChatResume',
    method: 'get',
    params: query
  })
}
export function setExchange(query) {
  return request({
    url: 'web/api/ImPrivate/setExchange',
    method: 'get',
    params: query
  })
}
export function getChatRecordResumeDetails(query) {
  return request({
    url: 'web/api/InterviewInfo/getChatRecordResumeDetails',
    method: 'get',
    params: query
  })
}
export function addInterviewInvitation(params) {
  return request({
    url: 'web/api/InterviewInfo/addInterviewInvitation',
    method: 'post',
    data: params
  })
}
export function getUserPersonalCenter(query) {
  return request({
    url: 'web/api/personalCenter/getUserPersonalCenter',
    method: 'get',
    params: query
  })
}
export function getPersonalDeliveryRecords(query) {
  return request({
    url: 'web/api/receivesResume/getPersonalDeliveryRecords',
    method: 'get',
    params: query
  })
}
export function getPersonalInterviewInfoList(query) {
  return request({
    url: 'web/api/InterviewInfo/getPersonalInterviewInfoList',
    method: 'get',
    params: query
  })
}
export function getInterviewInfoInfo(query) {
  return request({
    url: 'web/api/InterviewInfo/getInterviewInfoInfo',
    method: 'get',
    params: query
  })
}
export function getInterviewInfoList(query) {
  return request({
    url: 'web/api/InterviewInfo/getInterviewInfoList',
    method: 'get',
    params: query
  })
}
export function getEnterpriseReceivingRecords(query) {
  return request({
    url: 'web/api/receivesResume/getEnterpriseReceivingRecords',
    method: 'get',
    params: query
  })
}
export function getEnterprisePersonalCenterResponse(query) {
  return request({
    url: 'web/api/personalCenter/getEnterprisePersonalCenterResponse',
    method: 'get',
    params: query
  })
}
export function getWhoHasSeenMyData(query) {
  return request({
    url: 'web/api/footsteps/getWhoHasSeenMyData',
    method: 'get',
    params: query
  })
}
export function getBrowsingWhoHasSeenMyData(query) {
  return request({
    url: 'web/api/footsteps/getBrowsingWhoHasSeenMyData',
    method: 'get',
    params: query
  })
}
export function getQRCode(query) {
  return request({
    url: 'webApi/getQRCode',
    method: 'get',
    params: query
  })
}
export function getEnterpriseListByName(query) {
  return request({
    url: 'web/api/recruiters/getEnterpriseListByName',
    method: 'get',
    params: query
  })
}
export function getResumeCompletionRate(query) {
  return request({
    url: 'web/api/jobSeekers/getResumeCompletionRate',
    method: 'get',
    params: query
  })
}
export function getUnreadMessagesNum(query) {
  return request({
    url: 'web/api/ImFriend/getUnreadMessagesNum',
    method: 'get',
    params: query
  })
}
export function setUpMap(params) {
  return request({
    url: 'web/api/recruiters/setUpMap',
    method: 'post',
    data: params
  })
}
export function addEnterprises(params) {
  return request({
    url: 'web/api/blocking/addEnterprises',
    method: 'post',
    data: params
  })
}
export function getEnterprisesList(query) {
  return request({
    url: 'web/api/blocking/getEnterprisesList',
    method: 'get',
    params: query
  })
}
export function delEnterprises(query) {
  return request({
    url: 'web/api/blocking/delEnterprises/'+query,
    method: 'DELETE'
  })
}
export function getSeniorGradePosition(query) {
  return request({
    url: 'webApi/common/getSeniorGradePosition',
    method: 'get',
    params: query
  })
}
export function getSubordinateGradePosition(query) {
  return request({
    url: 'webApi/common/getSubordinateGradePosition',
    method: 'get',
    params: query
  })
}
export function getAuthenticationStatus(query) {
  return request({
    url: 'web/api/attestationManage/getAuthenticationStatus',
    method: 'get',
    params: query
  })
}
export function getColleagueManage(query) {
  return request({
    url: 'web/api/attestationManage/getColleagueManage',
    method: 'get',
    params: query
  })
}
export function delAttestation(params) {
  return request({
    url: 'web/api/attestationManage/delAttestation',
    method: 'post',
    data: params
  })
}

export function uploadEnterpriseCertificationReport(params) {
  return request({
    url: 'web/api/attestationManage/uploadEnterpriseCertificationReport',
    method: 'post',
    data: params
  })
}
export function getRecruitmentAnnouncement(query) {
  return request({
    url: 'web/api/talentIntroduction/getRecruitmentAnnouncement',
    method: 'get',
    params: query
  })
}
export function getTalentIntroductionEnterprises(query) {
  return request({
    url: 'web/api/talentIntroduction/getTalentIntroductionEnterprises',
    method: 'get',
    params: query
  })
}
export function getTalentIntroductionPositions(query) {
  return request({
    url: 'web/api/talentIntroduction/getTalentIntroductionPositions',
    method: 'get',
    params: query
  })
}
export function levelRegistration(params) {
  return request({
    url: 'web/api/talentIntroduction/levelRegistration',
    method: 'post',
    data: params
  })
}
export function getRegistrationList(query) {
  return request({
    url: 'web/api/talentIntroduction/getRegistrationList',
    method: 'get',
    params: query
  })
}
export function getPropsList(query) {
  return request({
    url: 'web/api/prop/getPropsList',
    method: 'get',
    params: query
  })
}
export function getUserProp(query) {
  return request({
    url: 'web/api/payConfig/getUserProp',
    method: 'get',
    params: query
  })
}
export function propUsage(params) {
  return request({
    url: 'web/api/prop/propUsage',
    method: 'post',
    data: params
  })
}
export function getPropsDetails(query) {
  return request({
    url: 'web/api/prop/getPropsDetails',
    method: 'get',
    params: query
  })
}
export function purchaseProps(params) {
  return request({
    url: 'web/api/payConfig/purchaseProps',
    method: 'post',
    data: params
  })
}
export function rechangeMember(params) {
  return request({
    url: 'web/api/payConfig/rechangeMember',
    method: 'post',
    data: params
  })
}
export function getObtainPaySwitch(params) {
  return request({
    url: 'web/api/payConfig/getObtainPaySwitch',
    method: 'post',
    data: params
  })
}
export function getOddJobBanner(query) {
  return request({
    url: 'web/api/temporaryWork/getOddJobBanner',
    method: 'get',
    params: query
  })
}

export function applicationForm(params) {
  return request({
    url: 'web/api/temporaryWork/applicationForm',
    method: 'post',
    data: params
  })
}
export function getMerchantOrNot(query) {
  return request({
    url: 'web/api/oddJobMerchant/getMerchantOrNot',
    method: 'get',
    params: query
  })
}
export function setMerchantAddress(params) {
  return request({
    url: 'web/api/oddJobMerchant/setMerchantAddress',
    method: 'post',
    data: params
  })
}
export function getServiceTrade(query) {
  return request({
    url: 'webApi/common/getServiceTrade',
    method: 'get',
    params: query
  })
}
export function getServiceDetails(query) {
  return request({
    url: 'web/api/temporaryWork/getServiceDetails',
    method: 'get',
    params: query
  })
}
export function getPlaceOrderAddress(query) {
  return request({
    url: 'web/api/temporaryWork/getPlaceOrderAddress',
    method: 'get',
    params: query
  })
}
export function setPlaceOrderAddress(params) {
  return request({
    url: 'web/api/temporaryWork/setPlaceOrderAddress',
    method: 'post',
    data: params
  })
}
export function addUserPlacesAnOrder(params) {
  return request({
    url: 'web/api/oddJobMerchantOrder/addUserPlacesAnOrder',
    method: 'post',
    data: params
  })
}
export function getQualification(query) {
  return request({
    url: 'web/api/recruiters/getQualification',
    method: 'get',
    params: query
  })
}
export function confirmChanges(params) {
  return request({
    url: 'web/api/recruiters/confirmChanges',
    method: 'post',
    data: params
  })
}

export function getObtainMerchantOrders(query) {
  return request({
    url: 'web/api/oddJobMerchant/getObtainMerchantOrders',
    method: 'get',
    params: query
  })
}
export function confirmCompletionOrder(params) {
  return request({
    url: 'web/api/oddJobMerchantOrder/confirmCompletionOrder',
    method: 'post',
    data: params
  })
}
export function cancelOrder(params) {
  return request({
    url: 'web/api/oddJobMerchantOrder/cancelOrder',
    method: 'post',
    data: params
  })
}
export function getMerchantInfo(query) {
  return request({
    url: 'web/api/oddJobMerchant/getMerchantInfo',
    method: 'get',
    params: query
  })
}
export function getUserOddJobOrder(query) {
  return request({
    url: 'web/api/oddJobMerchantOrder/getUserOddJobOrder',
    method: 'get',
    params: query
  })
}
export function confirmPayment(params) {
  return request({
    url: 'web/api/oddJobMerchantOrder/confirmPayment',
    method: 'post',
    data: params
  })
}
export function paymentCallback(params) {
  return request({
    url: 'web/api/oddJobMerchantOrder/paymentCallback',
    method: 'post',
    data: params
  })
}
export function getJoblessSwitch(query) {
  return request({
    url: 'web/api/temporaryWork/getJoblessSwitch',
    method: 'get',
    params: query
  })
}
export function addOrderEvaluation(params) {
  return request({
    url: 'web/api/oddJobMerchantOrder/addOrderEvaluation',
    method: 'post',
    data: params
  })
}
export function getUserOddJobOrderDetails(query) {
  return request({
    url: 'web/api/oddJobMerchantOrder/getUserOddJobOrderDetails',
    method: 'get',
    params: query
  })
}
export function getUrbanAreaList(query) {
  return request({
    url: 'webApi/common/getUrbanAreaList',
    method: 'get',
    params: query
  })
}
export function getWorkArea(query) {
  return request({
    url: 'webApi/common/getWorkArea',
    method: 'get',
    params: query
  })
}
export function getPurchaseHistory(query) {
  return request({
    url: 'web/api/payConfig/getPurchaseHistory',
    method: 'get',
    params: query
  })
}
export function getRechargeRecord(query) {
  return request({
    url: 'web/api/payConfig/getRechargeRecord',
    method: 'get',
    params: query
  })
}
export function getFriendlyLinks(query) {
  return request({
    url: 'webApi/advertisingFriendshiplink/getFriendlyLinks',
    method: 'get',
    params: query
  })
}
export function getAdvertisingSpaceList(query) {
  return request({
    url: 'webApi/advertisingFriendshiplink/getAdvertisingSpaceList',
    method: 'get',
    params: query
  })
}


export function getPayStatus(params) {
  return request({
    url: '/web/api/payConfig/getPayStatus',
    method: 'post',
    data: params
  })
}






























































